import React, {useEffect, useState} from 'react';
import TextType from '../../components/TextType';
import deliverOnTimeAndProfit from "../../static/deliver-on-time-and-profit-elapseit.svg";
import deliverOnTimeAndProfitIt from "../../static/deliver-on-time-and-profit-elapseit-it.svg";
import projectManagement from "../../static/project-management-elapseit.svg";
import projectManagementIt from "../../static/project-management-elapseit-it.svg";
import resourcePlanningAndForecast from "../../static/resource-planning-and-forecast-elapseit.svg";
import resourcePlanningAndForecastIt from "../../static/resource-planning-and-forecast-elapseit-it.svg";
import timeTrackingAndExpenseManagement from "../../static/time-tracking-and-expense-management-elapseit.svg";
import timeTrackingAndExpenseManagementIt from "../../static/time-tracking-and-expense-management-elapseit-it.svg";
import RequestDemoModal from './request-demo-modal';
import { Cookies } from 'react-cookie-consent';

export default function HomepageIndex(props) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const isBrowser = typeof window !== "undefined";
  let elapseitId = Cookies.get("elapseit_id");

  const [width, setWidth] = useState(isBrowser? window.innerWidth : 0);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const isMobile = width <= 768;

  const onStartFreeTrial = () => {
    if (isBrowser) {

      if (window && window.gtag) {
        window.gtag('event', 'sign_up', {
          'method': 'Homepage'
        });
        if (!isMobile) {
          window.gtag('event', 'conversion', {
            'send_to': 'AW-815257767/7PAgCLebrs8DEKex34QD',
            'event_callback': () => {
            }
          });
        }
      }
    }
  };


  return (
    <div className={"flex flex-col items-center"}>
      <RequestDemoModal isOpen={modalIsOpen} setIsOpen={setModalIsOpen} data={props.data} />
      <div className={"absolute w-full h-full background-inline"} />
      <div className={"flex flex-col relative"}>
        <h1 className={"text-xl md:text-2xl lg:text-4xl text-primary-default text-center pt-5 font-light"}> {props.data?.frontmatter?.headerText} <br/> {props.data?.frontmatter?.headerSubtext} </h1>
      </div>
      <div className={"relative w-full flex flex-col lg:flex-row items-center px-5 md:px-10 lg:px-20 xl:px-40"}>
        <div className={"relative w-full lg:w-1/2 xl:w-2/3"}>
          <div className={"background-left-image w-full bg-primary-default"}/>
        </div>
        <div className={"relative w-full lg:w-1/2 xl:w-1/3 flex flex-col"}>
          <p className={"text-xl md:text-xl lg:text-2xl xl:text-3xl text-primary-default font-light text-center"}>{props.data?.frontmatter?.freeTrialButtonHeader}</p>
          <p className={"text-primary-default font-light text-center"}>{props.data?.frontmatter?.freeTrialButtonSubHeader}</p>
          <div className={"flex flex-row self-center"}>
            {props.data?.frontmatter?.freeTrialButtonSubTexts.map((text, index) => <span key={index} className={"text-xs text-primary-default font-light px-2"}>{text}</span>)}
          </div>
          <a onClick={onStartFreeTrial} target={"_blank"} href={elapseitId ? ("https://app.elapseit.com/register?elapseitId=" + elapseitId + "&language=cz") : "https://app.elapseit.com/register?language=cz"} className={"text-white bg-primary-default border-2 border-primary-default rounded-lg w-2/3 lg:w-1/3 text-center self-center py-2 my-2 mb-2 cursor-pointer text-sm hover:text-white"}>
            {props.data?.frontmatter?.freeTrialButtonText}
          </a>
        </div>
      </div>
      <div className={"px-5 md:px-10 lg:px-20 xl:px-40 py-10 w-full z-10"}>
        <div className={"flex flex-col justify-center md:flex-row w-full lg:px-10"}>
          <span className={"text-lg md:text-xl lg:text-2xl xl:text-3xl text-gray-400 text-center pt-5 font-light px-2 w-full md:w-1/2 md:text-right"}>{props.data?.frontmatter?.wishMainText}</span>
          <div className={"flex flex-row w-full md:w-1/2 justify-center md:justify-start"}>
          <TextType textClasses={"text-lg md:text-xl lg:text-2xl xl:text-3xl text-primary-default md:pt-5 font-light"} blinkClasses={"text-lg md:text-xl lg:text-2xl xl:text-3xl md:pt-5 font-light"} blinkColor={" text-primary-default"} words={props.data?.frontmatter?.wishCarouselTexts} />
          </div>
        </div>
        <div className={"w-full flex flex-row items-center justify-center py-5"}>
          <p className={"lg:text-lg text-gray-500 w-full lg:w-2/3"}>{props.data?.frontmatter?.wishSubtext}</p>
        </div>
        <img loading="lazy" src={props.data?.frontmatter?.cloudSvg?.publicURL} alt={props.data?.frontmatter?.cloudSvgAlt} className={"py-10 hidden lg:block xl:px-20 xxl:px-40"} />
        {props.language === 'en' && <div className={"flex flex-col block lg:hidden items-center"}>
          <a className={"w-2/3 my-5"} href={"#planning"}><img loading="lazy" src={resourcePlanningAndForecast} alt={props.data?.frontmatter?.mobileCloudSvgAlts[0]}/></a>
          <a className={"w-2/3 my-5 self-center"} href={"#tracking"}><img loading="lazy" src={timeTrackingAndExpenseManagement} alt={props.data?.frontmatter?.mobileCloudSvgAlts[1]}/></a>
          <a className={"w-2/3 my-5"} href={"#management"}><img loading="lazy" src={projectManagement} alt={props.data?.frontmatter?.mobileCloudSvgAlts[2]}/></a>
          <a className={"w-2/3 my-5"} href={"#budgeting"}><img loading="lazy" src={deliverOnTimeAndProfit} alt={props.data?.frontmatter?.mobileCloudSvgAlts[3]}/></a>
        </div>}
        {props.language === 'it' && <div className={"flex flex-col block lg:hidden items-center"}>
          <a className={"w-2/3 my-5"} href={"#planning"}><img loading="lazy" src={resourcePlanningAndForecastIt} alt={props.data?.frontmatter?.mobileCloudSvgAlts[0]}/></a>
          <a className={"w-2/3 my-5 self-center"} href={"#tracking"}><img loading="lazy" src={timeTrackingAndExpenseManagementIt} alt={props.data?.frontmatter?.mobileCloudSvgAlts[1]}/></a>
          <a className={"w-2/3 my-5"} href={"#management"}><img loading="lazy" src={projectManagementIt} alt={props.data?.frontmatter?.mobileCloudSvgAlts[2]}/></a>
          <a className={"w-2/3 my-5"} href={"#budgeting"}><img loading="lazy" src={deliverOnTimeAndProfitIt} alt={props.data?.frontmatter?.mobileCloudSvgAlts[3]}/></a>
        </div>}
      </div>
    </div>
  );
};
